import http from '../../axios.js'
/*系统用户api*/

/** 
 * 判断手机号是否已注册
 * api.post 
 * body.phone : string
*/
// export function isExistPhone(data = {}) {
//   return http({
//     method: 'POST',
//     url: '/sysUser/isExistPhone',
//     data
//   })
// }
/**
 * 判断系统用户是否存在
 * @param {*} data 
 * @returns 
 */

export function isExistsUser(data = {}) {
  return http({
    method: 'POST',
    url: '/sysUser/isExistsUser',
    data
  })
}

/** 
 * 判断手机号状态
 * api.post 
 * body.phone : string
*/
export function checkPhoneStatus(data = {}) {
  return http({
    method: 'POST',
    url: '/sysUser/checkPhoneStatus',
    data
  })
}

/**
 * 查看用户信息
 * api.post
 * data.id
 */
export function getUserDetail(data = {}) {
  return http({
    method: 'POST',
    url: '/sysUser/detail',
    data
  })
}

/**
 * 增加用户
 * api.post 
 * body.id : number
 * body.userName : string
 * body.realName : string
 * body.phone : string
 * body.password : string
 * body.orgId : string
 * body.posId : string|null
 */
export function addUser(data = {}) {
  return http({
    method: 'POST',
    url: '/sysUser/add',
    data
  })
}

/**
 * 修改用户信息
 * api.post 
 * body.user_id : string
 * body.userName : string
 * body.company_id : string
 * body.department_id : string
 * body.position : string
 */
export function userUpdate(data = {}) {
  return http({
    method: 'POST',
    url: '/sysUser/update',
    data
  })
}

/** 
  * 修改用户名称
  * api.post
  * body.id:string
  * realName:string
*/
export function modifyName(data = {}) {
  return http({
    method: 'POST',
    url: '/sysUser/modifyName',
    data
  })
}

/**
 * 用户注销
 * api.post 
 * body.id : string
 * body.orgId : string|null
 */
export function userDelete(data = {}) {
  return http({
    method: 'POST',
    url: '/sysUser/delete',
    data
  })
}

/**
 * 用户手机号修改
 * api.post 
 * body.phone : string|null
 * body.code : string|null
 */
export function modifyPhone(data = {}) {
  return http({
    method: 'POST',
    url: '/sysUser/modifyPhone',
    data
  })
}

/**
 * 重置用户密码
 * api.post 
 */
export function resetPwd(data = {}) {
  return http({
    method: 'POST',
    url: '/sysUser/resetPwd',
    data
  })
}

/**
 * 用户邮箱修改
 * api.post 
 * body.user_id : string
 * body.email : string|null
 * body.code : string|null
 */
export function modifyEmail(data = {}) {
  return http({
    method: 'POST',
    url: '/sysUser/modifyEmail',
    data
  })
}

/**
 * 修改用户密码
 * api.post 
 * body.id : string
 * body.oldPassword : string
 * body.newPassword : string
 */
export function updatePwd(data = {}) {
  return http({
    method: 'POST',
    url: '/sysUser/updatePwd',
    data
  })
}

/**
 * 设置用户状态
 * api.post 
 * body.id : string
 * body.status : number
 */
export function setStatus(data = {}) {
  return http({
    method: 'POST',
    url: '/sysUser/setStatus',
    data
  })
}

/**
 * 邀请用户加入组织
 * api.post 
 * body.realName : string
 * body.phone : string
 * body.orgId : string
 * body.orgName : string
 */
export function invitation(data = {}) {
  return http({
    method: 'POST',
    url: '/sysUser/invitation',
    data
  })
}

/**
 * 退出组织
 * api.post
 */
export function quitOrg(data = {}) {
  return http({
    method: 'POST',
    url: '/sysUser/withdrawOrg',
    data
  })
}

/**
 * 申请加入组织
 * api.get
 * params.orgid
 */
// export function applyOrg(params = {}) {
//   return http({
//     method: 'GET',
//     url: '/sysUser/applyOrg',
//     params
//   })
// }


/**
 * 用户申请加入企业
 * api.get
 * params.orgid
 */
export function applyJoin(data = {}) {
  return http({
    method: 'POST',
    url: '/api/invitation/applyJoin',
    data
  })
}

/**
 * 查看我的申请记录
 * api.post
 */
export function getApplyList(data = {}) {
  return http({
    method: 'POST',
    url: '/sysUser/getInvites',
    data
  })
}

/**
 * 撤销我的申请记录
 * api.get
 */
export function cancelApply(data = {}) {
  return http({
    method: 'POST',
    url: '/api/invitation/cancelApplyJoin',
    data
  })
}



