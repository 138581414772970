
<template>
  <div class="dplayer_box">
    <header>
      <div class="name">
        {{ file_name || "暂无会议名称" }}
      </div>
      <div class="info">
        会议号：<span>{{ meetingInfo.meeting_code }}</span>

        会议时间：
        <span>{{ meetingInfo.start_time }} -- {{ meetingInfo.end_time }}</span>
      </div>
    </header>
    <div class="dplayer" :id="id"></div>
  </div>
</template>

<script>
import DPlayer from "dplayer";
export default {
  name: "dplayer",
  data() {
    return {
      id: null,
      options: {},
      player: null,
      url: "",

      query: {},
      meetingInfo: {},
      fileinfo: {},
      file_name: "",
      file_id: "",
    };
  },

  methods: {
    initPlayer() {
      this.options = {
        container: document.querySelector("#" + this.id), // 播放器容器
        mutex: false, //  防止同时播放多个用户，在该用户开始播放时暂停其他用户
        theme: "#b7daff", // 风格颜色，例如播放条，音量条的颜色
        loop: false, // 是否自动循环
        lang: "zh-cn", // 语言，'en', 'zh-cn', 'zh-tw'
        hotkey: true, // 是否支持热键，调节音量，播放，暂停等
        preload: "auto", // 自动预加载
        volume: 0.7, // 初始化音量
        autoplay: true,
        muted: true,
        video: {
          url: this.url,
        },
      };
      this.player = new DPlayer(this.options);
    },
    getMeetingInfo(id) {
      this.$axios({
        method: "get",
        url: this.query.baseUrl + `/api/MeetingInfo/GetMeetingInfo/${id}`,
        params: {
          meeting_id: id,
        },
      }).then((res) => {
        this.meetingInfo = res.data;
      });
    },
    getFileBlob(id) {
      this.$axios({
        method: "get",
        url: this.query.baseUrl + `/api/SystemFile/PreviewFile/${id}`,
        responseType: "blob",
        timeout: 100000000,
      }).then((res) => {
        this.url = URL.createObjectURL(
          new Blob([res], {
            type: res.type,
          })
        );
        this.initPlayer();
      });
    },
  },
  created() {
    const number = Math.random();
    this.id = "dplayer" + String(number).split(".")[1];
  },
  mounted() {
    this.query = this.$route.query;
    this.file_name = this.query.name;
    this.getMeetingInfo(this.$route.query.meeting_id);
    // this.getFileBlob(this.$route.query.file_id);
    this.url =
      this.query.baseUrl + `/api/System/FilePreViewer/${this.query.file_id}`;
    this.initPlayer();
  },
  beforeDestroy() {
    this.player.destroy();
  },
};
</script>

<style lang='scss' scoped>
.dplayer_box {
  width: 100%;
  height: calc(100% - 60px);

  header {
    height: 60px;
    background-color: #eee;
    font-size: 16px;
    font-weight: 700;
    overflow: hidden;
    > div {
      margin: 8px 0 0 15px;
    }
    .info {
      font-size: 14px;
      span {
        color: gray;
      }
    }
  }

  .dplayer {
    height: 100%;
    width: 100%;
  }
}
</style>