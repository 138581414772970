import Vue from "vue";
import axios from "axios";
import qs from "qs";
import store from "@/store";
import router from "@/router";
import i18n from "@/locale";
import Cookies from "js-cookie";

let baseurl;

async function init() {
  const { data: config } = await axios.get("/config.json");
  
  if (config.baseurl) axios.defaults.baseURL = config.baseurl;
}
init();

axios.defaults.baseURL = baseurl || location.origin; //先获取本地服务，若配置文件中有其它服务，则会被改为配置的服务

/* 超时时间和是否允许跨域凭证 */
axios.defaults.timeout = 15000;
axios.defaults.withCredentials = false;

/* 请求数据传递的格式 */
axios.defaults.headers["Content-Type"] = "application/json";

// 创建一个axios实例，用于刷新token
const instance = axios.create({
  baseURL: baseurl,
  timeout: 10000,
});

//一些需要绕过全局拦截的接口
let whiteList = [];

/* 拦截器 */
axios.interceptors.request.use(
  (config) => {
    if (config.method === "get") {
      //如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
      config.paramsSerializer = function(params) {
        return qs.stringify(params, { arrayFormat: "repeat" });
      };
      config.params = { ...config.params, t: new Date().getTime() };
    }
    //携带上token
    let token = localStorage.getItem("ihub_token");
    token && (config.headers.Authorization = `Bearer ${token}`);
    let lang = Cookies.get("lang");
    lang && (config.headers["Accept-Language"] = lang);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async (response) => {
    //存储token和refresh-token
    let token = response.headers["access-token"];
    let x_token = response.headers["x-access-token"];

    if (x_token) {
      store.commit("SET_X_TOKEN", x_token);
      localStorage.setItem("x_token", x_token);
    }
    if (token) {
      store.commit("SET_TOKEN", token);
      localStorage.setItem("ihub_token", token);
    }

    //全局抛错处理
    if (response.data.code != 200) {
      //错误信息获取
      let code = response.data.code; //请求状态码
      let errorCode = response.data.errorCode; //请求错误码--对应具体错误
      let errorMessage = response.data.message; //具体错误信息

      //code为403或者401的时候代表用户身份已经过期
      if (code == 403 || code == 401) {
        // 清除缓存
        localStorage.clear();
        sessionStorage.clear();
        // 跳往登录页
        setTimeout(() => {
          router.replace({ name: "login" });
        }, 1500);
        return Vue.prototype.$message.error(i18n.t("axios.expire"));
      }

      // 以下代码已经作废
      /* //u1004的错误码时有对应路由的，代表着账号审核失败，将去往对应页面 
      try {
        if (errorCode.includes('u1004')) {
          router.push({ name: 'fail' })
        }
      }
      catch { }

      //u1015的错误码对应的是未注册用户，跳往信息注册页面，并携带上信息
      try {
        if (errorCode.includes('u1015')) {
          console.log(response)
          let tel = JSON.parse(response.config.data).phone
          Vue.prototype.$message.success(i18n.t('axios.noRegister'))
          sessionStorage.setItem('register', tel)

          setTimeout(() => {
            router.push({ name: 'register' })
          }, 500);

          errorMessage = ''
        }
      }
      catch { } */

      //弹出对应提示框，后端的返回可能不是字符串
      if (typeof errorMessage == "string" && errorMessage) {
        Vue.prototype.$message.error(errorMessage);
      } else if (typeof errorMessage == "object") {
        Vue.prototype.$message.error(JSON.stringify(errorMessage));
      }

      //全局拦截抛出错误
      if (!whiteList.find((i) => i == response.config.url)) {
        throw "[ajax error]:check network,statusCode maybe not 200";
      }
    }
    return response.data;
  },

  async (error) => {
    //处理401
    if (error.response && error.response.status === 401) {
      // 开始处理
      const token = localStorage.getItem("ihub_token");
      const refreshToken = localStorage.getItem("x_token");

      // 1. 请求新token
      instance({
        url: "/auth/refreshToken",
        method: "get",
        headers: {
          "X-Authorization": `Bearer ${refreshToken}`,
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          // 2. 保存到vuex和localStorage
          store.commit("SET_X_TOKEN", response.headers["x-access-token"]);
          localStorage.setItem("x_token", response.headers["x-access-token"]);

          store.commit("SET_TOKEN", response.headers["access-token"]);
          localStorage.setItem("ihub_token", response.headers["access-token"]);

          // 3. 重发请求
          // console.log(error.config, 'xxxxx');
          return axios(error.config);
        })
        .catch((error) => {
          // console.log('x-token已过期');
          Vue.prototype.$message.error(i18n.t("axios.pleaseLogin"));

          // 清除缓存
          localStorage.clear();
          sessionStorage.clear();

          // 跳往登录页
          setTimeout(() => {
            router.push({ name: "login" });
          }, 1500);
        });
    }

    //处理403
    if (error.response && error.response.status === 403) {
      // console.log('用户身份已过期');
      Vue.prototype.$message.error(i18n.t("axios.expire"));

      // 清除缓存
      localStorage.clear();
      sessionStorage.clear();

      // 跳往登录页
      setTimeout(() => {
        router.replace({ name: "login" });
      }, 1500);
    }

    return Promise.reject(error);
  }
);
export default axios;
