/*
 * @Author: 朱开 1396509050@qq.com
 * @Date: 2022-07-29 11:34:10
 * @LastEditors: chenyn 1826650603@qq.com
 * @LastEditTime: 2023-11-16 14:55:13
 * @FilePath: \Web\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import setMessage from '@/tools/resetMessage.js';
import * as echarts from "echarts";
import 'element-ui/lib/theme-chalk/index.css';

import axios from '@/api/axios.js'
import FileSaver from 'file-saver'

import $ from '@/jquery/jquery.min.js'
import xlsx from 'xlsx'
import i18n from './locale'
import Cookies from 'js-cookie'

Vue.use(i18n)
Vue.prototype.$ = $
Vue.prototype.$echarts = echarts;
Vue.prototype.$xlsx = xlsx
Vue.prototype.$fileSaver = FileSaver

Vue.prototype.$lang = Cookies.get('lang') || 'zh-CN'

Vue.use(ElementUI, {
  size: 'small',
  i18n: (key, value) => i18n.t(key, value)
})
Vue.prototype.$axios = axios
Vue.prototype.$message = setMessage // ------  只允许存在一个message

Vue.config.productionTip = false

//关掉页面重新进入时对身份信息和侧边栏进行处理
let info = localStorage.getItem("adminOrUser")
if (info) {
  store.commit("SET_ADMINORUSER", info);
}

// 刷新保存状态
if (sessionStorage.getItem('store')) {
  store.replaceState(
    Object.assign({},
      store.state,
      JSON.parse(sessionStorage.getItem('store'))
    )
  )
  sessionStorage.removeItem('store')
  // 刷新如果身份变化弹回其主页
  // if(store.state.adminOrUser != info) {
  //    if(store.state.adminOrUser == 'admin') {
  //       router.replace({ name: 'dataMain' })
  //    }
  //    else {
  //       router.replace({ name: 'myMeeting' })
  //    }
  // }
}

// 监听，在页面刷新时将vuex里的信息保存到sessionStorage里,同时存下当前路由
window.addEventListener('beforeunload', () => {
  console.log(route_name, 'route_name=====')
  // 跳转补丁   register注册
  if (router.currentRoute.name = 'register') {
    sessionStorage.setItem('route_name', "")
  }
  sessionStorage.setItem('store', JSON.stringify(store.state))
  sessionStorage.setItem('route_name', router.currentRoute.name)
  


})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')