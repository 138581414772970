/*
 * @Author: 朱开 1396509050@qq.com
 * @Date: 2022-08-12 18:45:53
 * @LastEditors: chenyn 1826650603@qq.com
 * @LastEditTime: 2023-11-22 13:55:56
 * @FilePath: \Web\src\api\api\auth\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import http from "../../axios.js";
import Cookies from 'js-cookie'
/*登录授权api*/

/**
 * 密码登录
 * api.post
 * body.account : string
 * body.password : string
 */
export function loginpwd(data = {}) {
  return http({
    method: "POST",
    url: "/auth/loginpwd",
    data,
  });
}

/**
 * 验证码登录 update:2023.10.31 手机、邮箱
 * api.post
 * body.phone : string|null
 * body.code : string|null
 */
export function logincode(data = {}) {
  return http({
    method: "POST",
    url: "/auth/logincode",
    data,
  });
}

/**
 * 账号注册
 * api.post
 * body.phone : string|null
 * body.name : string|null
 * body.password : string & length >= 8
 */
export function register(data = {}) {
  let url =Cookies.get('lang') === 'zh-CN' ? '/auth/registerbyphone' : '/auth/registerbyemail'
  return http({
    method: "POST",
    url:url,
    data,
  });
}

/**
 * 获取用户信息
 * api.get
 */
export function getLoginUser() {
  return http({
    method: "GET",
    url: "/auth/getLoginUser",
  });
}


/**
 * 用户审核状态查询（0：待审核 1：审核通过 2：审核拒绝）
 * api.get
 */
export function checkUserAuditStatus() {
  return http({
    method: "GET",
    url: "/api/invitation/checkUserAuditStatus",
  });
}

/**
 * 退出登录
 * api.post
 * body.phone : string|null
 * body.name : string|null
 * body.password : string & length >= 8
 */
export function logout(data = {}) {
  return http({
    method: "GET",
    url: "/auth/logout",
    data,
  });
}

/**
 * 刷新令牌
 * api.get
 */
export function refreshToken(params = {}) {
  return http({
    method: "GET",
    url: "/auth/refreshToken",
    params,
  });
}

/**
 * 检验邮箱是否被使用
 * api.get
 */
export function isExistEmail(params = {}) {
  return http({
    method: 'POST',
    url: '/auth/isexistEmail',
    params,
  })
}


/**
 * 检验用户是否已注册
 * api.get
 */
export function isExistsUserApi(data = {}) {
  return http({
    method: 'POST',
    url: '/sysUser/isExistsUser',
    data,
  })
}