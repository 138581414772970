/*
 * @Author: chenyn 1826650603@qq.com
 * @Date: 2023-10-11 09:01:00
 * @LastEditors: chenyn 1826650603@qq.com
 * @LastEditTime: 2023-10-20 10:54:59
 * @FilePath: \Web\src\locale\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import VueI18n from "vue-i18n";
import Cookies from "js-cookie";
import elementEnLocale from "element-ui/lib/locale/lang/en"; // element-ui 英文
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN"; // element-ui 简体中文
import elementRuLocale from "element-ui/lib/locale/lang/ru-RU"; // element-ui 简体中文
import enLocale from "./lang/en";
import zhLocale from "./lang/zh-cn";
import ruLocale from "./lang/ru";

Vue.use(VueI18n);

const messages = {
  "zh-CN": {
    ...zhLocale,
    ...elementZhLocale,
  },
  "en-US": {
    ...enLocale,
    ...elementEnLocale,
  },
  "ru-RU": {
    ...ruLocale,
    ...elementRuLocale,
  },
};

let BrowserLanguage = window.navigator.language;

if (!Cookies.get("lang")) {
  switch (BrowserLanguage) {
    case "zh-CN":
      Cookies.set("lang", "zh-CN");
      break;
    case "en":
      Cookies.set("lang", "en-US");
      break;
    case "ru":
      Cookies.set("lang", "ru-RU");
      break;
    default:
      Cookies.set("lang", "en-US");
      break;
  }
}

const i18n = new VueI18n({
  locale: Cookies.get("lang") || "en-US",
  messages,
});

Vue.prototype.$lang = Cookies.get("lang") || "zh-CN";
export default i18n;