
<template>
  <div class="register_fail">
    <div class="left">
      <div class="header">
        <img :src="logoUrl" alt />
        <div>{{  $t('global.title') }}</div>
      </div>

      <div class="body">
        <img :src="mapUrl" alt />
        <div>
          您的申请未通过审核 , 如需重新申请请点击
          <span id="a" @click="gotoRegister">重新申请</span>
        </div>
        <div>详细情况请联系管理员</div>
      </div>
    </div>

    <img class="right" :src="bgUrl" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      mapUrl:require("./assets/Sketch Map.png"),
      bgUrl:require("./assets/bg@2x.png"),
      logoUrl:require("./assets/logo@2x.png")
    }
  },
  methods: {
    gotoRegister() {
      this.$router.replace({ name: "register" });
    },
  },
};
</script>

<style lang='scss' scoped>
#a {
  text-decoration: none;
  color: #298cff;
  cursor: pointer;
}
body {
  min-width: 100vw;
}
.register_fail {
  height: 100%;
  display: flex;
  justify-content: space-between;
  .left {
    width: 100%;
    .header {
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 0 20px;

      font-size: 18px;
      font-weight: 700;
      height: 70px;
      img {
        height: 48px;
        width: 48px;
        margin-right: 15px;
      }
    }

    .body {
      width: 100%;
      margin: 260px auto;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      > img {
        width: 150px;
        height: 150px;
      }

      > div {
        margin: 20px;
      }
      > div:last-child {
        margin: 0;
      }
    }
  }

  .right {
    height: 100%;
    width: auto;
  }
}
</style>
