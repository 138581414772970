import http from '../../axios.js'
/*验证码api*/

/** 
 * 发送验证码
 * api.post 
 * body.receiver : string
*/
export function sendCaptcha(data = {}) {
  return http({
    method: 'POST',
    url: '/captcha/sendCaptcha',
    data
  })
}

/**
 * 校验验证码
 * api.post 
 * body.receiver : string|null
 * body.code : string|null
 */
export function checkCaptcha(data = {}) {
  return http({
    method: 'POST',
    url: '/captcha/checkCaptcha',
    data
  })
}

