<!--
 * @Author: chenyn 1826650603@qq.com
 * @Date: 2023-10-11 09:01:00
 * @LastEditors: chenyn 1826650603@qq.com
 * @LastEditTime: 2023-10-25 16:58:44
 * @FilePath: \Web\src\_register\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="login">
    <div class="left">
      <Header />
      <Getcode @nextStep="next" v-if="curStep == 1 && lang === 'zh-CN'"></Getcode>
      <GetEmailCode @nextStep="next" v-if="curStep == 1 && lang !== 'zh-CN'"></GetEmailCode>
      <Info @nextStep="next" v-if="curStep == 2"></Info>
      <Success v-if="curStep == 3"></Success>
      <Footer />
    </div>
    <img class="right" :src="bgUrl" alt />
  </div>
</template>

<script>
import Cookie from "js-cookie";
export default {
  data() {
    return {
      logoUrl: require("@/assets/router/logo.png"),
      bgUrl: require("./assets/bg@2x.png"),
      curStep: 1,
    };
  },
  computed: {
    lang() {
      return Cookie.get("lang") || "en-US";
    },
  },
  methods: {
    next(step, phone) {
      if (step == "getcode") {
        sessionStorage.setItem("curstep", (this.curStep = 2));
        sessionStorage.setItem("phone", phone);
        sessionStorage.setItem("email", phone);
      }
      if (step == "setinfo")
        sessionStorage.setItem("curstep", (this.curStep = 3));
    },
  },
  components: {
    Getcode: () => import("./components/getcode.vue"),
    GetEmailCode: () => import("./components/getEmailCode.vue"),
    Info: () => import("./components/info.vue"),
    Success: () => import("./components/success.vue"),
    Header: () => import("@/components/common/header.vue"),
    Footer: () => import("@/components/common/footer.vue")
  },
  mounted() {
    !sessionStorage.getItem("curstep") &&
      sessionStorage.setItem("curstep", this.curStep);

    //如果是从登录1015报错过来的，直接进入info阶段
    if (sessionStorage.getItem("register")) {
      sessionStorage.setItem("curstep", 2);
      sessionStorage.setItem("phone", sessionStorage.getItem("register"));
      sessionStorage.setItem("email", sessionStorage.getItem("register"));
      this.curStep = 2;

      sessionStorage.removeItem("register");
    }
  },

  beforeDestroy() {
    sessionStorage.removeItem("curstep");
  },
};
</script>

<style lang="scss" scoped>
body {
  min-width: 100vw;
}

a {
  text-decoration: none;
  color: #298cff;
}

.login {
  display: flex;
  justify-content: space-between;
  height: 100%;

  .left {
    width: 100%;
  }

  .right {
    display: block;
    height: 100%;
    width: auto;
  }
}</style>
